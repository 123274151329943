exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-article-index-jsx": () => import("./../../../src/pages/article/index.jsx" /* webpackChunkName: "component---src-pages-article-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-marsel-privacy-policy-index-jsx": () => import("./../../../src/pages/marsel-privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-marsel-privacy-policy-index-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("./../../../src/pages/projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-template-article-template-jsx": () => import("./../../../src/template/ArticleTemplate.jsx" /* webpackChunkName: "component---src-template-article-template-jsx" */),
  "component---src-template-blog-article-jsx": () => import("./../../../src/template/BlogArticle.jsx" /* webpackChunkName: "component---src-template-blog-article-jsx" */),
  "component---src-template-career-detail-jsx": () => import("./../../../src/template/CareerDetail.jsx" /* webpackChunkName: "component---src-template-career-detail-jsx" */),
  "component---src-template-case-study-detail-jsx": () => import("./../../../src/template/caseStudyDetail.jsx" /* webpackChunkName: "component---src-template-case-study-detail-jsx" */),
  "component---src-template-landing-page-template-jsx": () => import("./../../../src/template/LandingPageTemplate.jsx" /* webpackChunkName: "component---src-template-landing-page-template-jsx" */),
  "component---src-template-solution-jsx": () => import("./../../../src/template/solution.jsx" /* webpackChunkName: "component---src-template-solution-jsx" */),
  "component---src-template-solution-list-jsx": () => import("./../../../src/template/SolutionList.jsx" /* webpackChunkName: "component---src-template-solution-list-jsx" */)
}

